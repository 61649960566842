import HostUrl from '@/utils/HostUrl.js'

const hostUrl = new HostUrl()

const host = hostUrl.getUrl('')
const goods_host = hostUrl.getUrl('GOODS')
const aftersale_host = hostUrl.getUrl('AFTER_SALE')
const scm_host = hostUrl.getUrl('SCM')
const region_host = hostUrl.getUrl('REGION')
const inventory_host = hostUrl.getUrl('INVENTORY')
const task_host = hostUrl.getUrl('REPORT')
const idaas_host = hostUrl.getUrl('IDAAS')
const shop_host = hostUrl.getUrl('SHOP')
const order_host = hostUrl.getUrl('ORDER')
const plymouth_host = hostUrl.getUrl('PLYMOUTH')
const workorder_host = hostUrl.getUrl('WORK_ORDER')
export default {
  getDownloadKey: `${host}/oauth/download/key`, //获取临时下载的key
  preUploadFile: `${host}/ossapi/file/preUploadFile`, //文件预上传
  //原始订单列表页使用接口
  getOriginOrderList: `${plymouth_host}/original_order/list`, //查询原始订单列表                          original_order/list
  updateOriginOrderAddress: `${plymouth_host}/original_order/updateAddress`, //原始单更新地址信息(只能修改省市区街道，不包括详细地址) original_order/updateAddress
  updateOriginOrderGoodsOuterCoder: `${plymouth_host}/original_order/updateOuterCode`, //原始单商家编码            original_order/updateOuterCode
  originOrderDelete: `${plymouth_host}/original_order/delete`, //原始单删除接口                            original_order/delete
  syncPlatformOrder: `${plymouth_host}/platform_order/sync`, //原始单同步
  modifyReceiverName: `${plymouth_host}/original_order/modifyReceiverName`, //原始单同步
  batchForceTransform: `${plymouth_host}/original_order/force/transform`, //批量强制转化
  getPlymouthOrderActionList: `${plymouth_host}/unified_order_action/list`, //原始订单操作日志
  printShippingList: `${plymouth_host}/original_order/print`, //打印发货清单
  batchSyncPlatformOrder: `${plymouth_host}/platform_order/batch/sync`, //批量拉单

  getOrdetTypeList: `${plymouth_host}/orderType/all`, //获取订单类型接口
  getOrdetSubTypeList: `${plymouth_host}/orderType/list`, //获取订单子类型接口
  getConversionFailNum: `${plymouth_host}/original_order/count`, //转化失败数量

  //商品搜索
  getSkuList: `${goods_host}/sku/query`, //查询sku列表
  getCategoryMap: `${goods_host}/category/get/map`, //类目map
  videoUpload: `${goods_host}/video/upload`, //上传视频

  //录单
  getOriginOrder: `${plymouth_host}/original_order/detail`, //加载订单信息                        original_order/detail
  addOrder: `${plymouth_host}/original_order/manually/add`, //生成订单

  //批量录单
  uploadBatchOrderFile: `${plymouth_host}/file/upload`, //通过Excel上传录单，然后上传                   file/upload

  //订单详情页
  getOrderDetail: `${order_host}/order/detail`, //oms订单的详情查询
  addOrderAction: `${order_host}/order_action/add`, //添加oms订单的相应的留言信息                        order_action/add
  getOrderActionList: `${order_host}/order_action/list`, //oms订单的留言/备注列表查询                   order_action/list
  getOrderTrackingList: `${order_host}/order/track`, //订单的物流信息
  modifyOrderFacilityShipping: `${order_host}/order/updateShipping`, //oms订单的仓库快递修改          order/updateShipping
  modifyOrderReceiverInfo: `${order_host}/order/updateReceiver`, //oms订单的收货信息修改              order/updateReceiver
  modifyPostAndDiscountFee: `${order_host}/order/updateFee`, //订单优惠和邮费 修改            order/updateFee
  modifyOrderGoods: `${order_host}/order/updateSku`, //订单商品修改                            order/updateSku
  addOrderGoods: `${order_host}/order/addSku`, //订单单品添加                          order/addSku
  addOrderGroupGoods: `${order_host}/order/addGroup`, //订单组合商品添加                       order/addGroup
  deleteOrderGoods: `${order_host}/order/deleteSku`, //订单商品删除                            order/deleteSku
  splitOrder: `${order_host}/order/split`, //拆分订单
  splitOrderPlus: `${order_host}/order/split2`, //拆分订单2                          order/split2
  splitOrderCalculate: `${order_host}/order/split/calculate`, //订单拆单计算接口
  splitOrderPreview: `${order_host}/order/split/preview`, //订单拆单预览
  getOrderWarehouseInventorySummary: `${order_host}/inventory/summary/order/warehouse`, //订单可用仓库库存查询
  modifyOrderBook: `${order_host}/order/modify/book`, //修改预定单
  clearExceptionTag: `${order_host}/order/removeException`, //清除异常标签                     order/removeException
  urgentDeliver: `${order_host}/order/manualDeliver`, //紧急发货接口                           order/manualDeliver
  supplyCompensationGet: `${order_host}/order/supply/loss/detail/get`, //补寄单理赔信息获取接口
  supplyCompensationModify: `${order_host}/order/supply/loss/detail/modify`, //补寄理赔详情修改
  orderMemoModify: `${order_host}/order/memo/modify`, //修改备注
  deliveredOrder: `${order_host}/order/sof/delivered`, //手动签收
  revertWaitCheck: `${order_host}/order/revert/waitCheck`, //恢复待审核
  updateGoodsSkuId: `${order_host}/order/order/goods/updateSkuId`, //商品修改sku
  interceptOrder: `${order_host}/order/create/intercept/order`, //拦截
  updateBatchCode: `${order_host}/order/goods/updateBatchCode`, //订单商品添加批次号
  exportShippingList: `${order_host}/order/exportShippingList`, //导出订货明细
  manualCloseOrder: `${order_host}/order/manual/close`, //手动关闭订单
  addDeduction: `${order_host}/deduction/add`, //代扣款
  getOrderStatusList: `${order_host}/statusInfo/listByType`, //订单状态列表 get
  getOmsOrderList: `${order_host}/out/order/list`, //获取oms订单商品
  sendRTSOrder: `${order_host}/order/rts/send`, //回传瑞本云
  exportNoOrderShopList: `${order_host}/order/noOrderShopList`, //导出未下单店铺
  queryOrderCount: `${order_host}/order/count`, //查询未审核大蛋糕订单数
  getOrderDownloadParamKey: `${order_host}/order/download/param/key`, //生成导出key
  changeReserveCondition: `${order_host}/order/changeReserveCondition`, //修改预定条件

  // oms列表
  getSaleOrderList: `${order_host}/order/list`, // 查询销售订单列表
  getSaleOrderExceptionTagNum: `${order_host}/order/exceptionTagNum`, //查询销售订单异常标签
  batchAddSku: `${order_host}/order/batchAddSku`, //批量添加赠品
  checkOrders: `${order_host}/order/batchCheck`, // 订单批量审核                           order/batchCheck
  checkBatch: `${plymouth_host}/original_order/check/batch`, // 原始订单批量审核
  cancelOrders: `${order_host}/order/batchCancel`, // 订单批量作废                         order/batchCancel
  cancelOrder: `${order_host}/order/cancel`, // 订单作废                         order/cancel
  rejectOrders: `${order_host}/order/batchReject`, //出库驳回                                order/batchReject
  forceCheckOrders: `${order_host}/order/batchForceCheck`, // 订单批量强制审核                       order/batchForceCheck
  mergeOrders: `${order_host}/order/merge`, //合并订单
  changeOrdersType: `${order_host}/order/batchUpdate`, // 订单批量修改订单子类型接口              order/batchUpdate
  batchAddOrderExceptionFlag: `${order_host}/order/batchAddOrderExceptionFlag`, //批量加异常标签
  batchClearOrderExceptionFlag: `${order_host}/order/batchClearOrderExceptionFlag`, //批量清空异常标签
  reminderOrder: `${order_host}/order/reminder`, //加急
  queryReservedOrderSkus: `${order_host}/order/queryReservedOrderSkus`, //查询商品的预订订单
  modifyDeliverTime: `${order_host}/order/deliverTime/modify`, //修改预计到货时间

  //scm查询分销商
  getDistributorListNew: `${scm_host}/distribution/pageList`, //分销商列表

  //查询单品库存
  skuStockQuerySkuStockForOms: `${inventory_host}/skuStock/querySkuStockForOms`, //查询barcode可用库存
  querySkuStockForBatch: `${inventory_host}/skuStock/querySkuStockForBatch`, //查询barcode批次

  //task任务
  createTask: `${task_host}/task/create`, //oms导出

  //售后列表
  aftersaleOrderInfo: `${aftersale_host}/aftersale/order/query/info`, //售后原因列表                aftersale/order/query/info
  aftersaleOrderAllStatus: `${aftersale_host}/aftersale/order/get/map`, //售后状态列表                aftersale/order/get/map
  getStoreReturnList: `${aftersale_host}/aftersale/order/list`, //查询门店退货列表
  batchImportStoreReturn: `${aftersale_host}/aftersale/order/batch/return/import`, //查询门店退货列表导入

  //批量审单任务
  inspectionTaskAdd: `${order_host}/inspection/task/add`, //新建批量任务接口
  inspectionTaskList: `${order_host}/inspection/task/list`, //审单任务查询接口
  inspectionTaskDetail: `${order_host}/inspection/task/detail`, //任务明细查询接口
  inspectionTaskOrderDetail: `${order_host}/inspection/task/order/detail`, //订单明细导出接口
  inspectionTaskStop: `${order_host}/inspection/task/stop`, //停止接口
  inspectionTaskORderCount: `${order_host}/inspection/task/order/count`, //订单量查询接口

  //地址库管理
  getRegionList: `${region_host}/region/all`, //省市区下拉框
  saveRegionMappingList: `${region_host}/regionManage/saveReg`, //新增修改对应地址映射关系
  deleteRegionMappingList: `${region_host}/regionManage/deleteRegion`, //删除对应地址映射关系
  getRegionTree: `${region_host}/regionManage/getRegionTreeSubFront`, //查询region树形结构
  getRegionMappingList: `${region_host}/regionManage/getRegionMappingByIdFront`, //查询对应地址映射关系

  //idaas应用校验
  queryIdaasAppList: `${idaas_host}/open/merchantApp/list`, //idaas应用列表
  querySystemMessage: `${idaas_host}/open/systemMessage/receive`, //发布消息
  getCounrtyList: `${idaas_host}/region/countryList`, //国家列表
  registerMerchant: `${idaas_host}/merchant/register`, //注册商户
  queryRegisterProgress: `${idaas_host}/merchant/queryRegisterProgress`, //查询商户注册进度
  sendResetPasswordMessage: `${idaas_host}/user/sendResetPasswordMessage`, //重置密码

  //shop服务
  getWarehouseShipperList: `${shop_host}/shipper/page`, //货主列表
  getNewShopList: `${shop_host}/shop/page`, //新店铺列表
  getNewShopInfoList: `${shop_host}/shop/simplePage`, //新店铺选择
  getWarehouseListNew: `${shop_host}/warehouse/queryWarehousePage`, //仓库列表
  getWarehouseListByShop: `${shop_host}/shop/listWarehouseByShopList`, //根据店铺获取仓库列表
  getShippingListByShopAndWarehouse: `${shop_host}/shop/listShippingByShopAndWarehouse`, //快递列表
  getShippingList: `${shop_host}/shipping/page`, //快递列表
  getShippingListByUser: `${shop_host}/shipping/listByUser`, //快递列表

  workOrderList: `${workorder_host}/workorder/list`, //列表

  clearMYJData: 'https://myj.shopspade.com/api/openWeb/myj/order/truncate', //清空美宜佳订单数据
}
